export default Object.freeze({
  common: {
    next: "Дальше",
    back: "Назад",
    cancel: "Отмена",
    close: "Закрыть",
    another: "Другое",
    pause: "Технический перерыв, <br /> мы вернемся с минуты на минуту"
  },
  home: {
    title: "Продажа скинов",
    advantagesCards: [
      {
        title: "Лучшее <br> предложение",
        text: "Лучшие цены<br> для мгновенной продажи"
      },
      {
        title: "Без скрытых <br> комиссий",
        text: "Вы сразу видите <br> конечную сумму"
      },
      {
        title: "Мгновенные <br> выплаты",
        text: "Время поступления <br> средств ~15 минут"
      }
    ],
    four_steps_for: "4 шага чтобы",
    sell_your_skins: "продать свои скины",
    enter_your_trade: "Введите вашу трейд",
    link: "ссылку",
    enter_your_trade_link: "Введите вашу трейд ссылку",
    if_inventory_not_load: "Введите ссылку, если ваш инвентарь не загружается",
    choose_skins_for_sale: "Выберите скины для продажи",
    choose_skins_for_sale_caption: "Не менее {minWithdrawAmount} руб.",
    choose_skins_for_sale_caption1: "Выбрано на {offerPrice} руб.",
    choose_skins_tooltip:
      "Выберите скинов <br /> на сумму не менее {minWithdrawAmount} руб.",
    choose_payment_system: "Выберите платежную систему",
    enter_recipient: "Введите платежные реквизиты",
    get_trade_url: "Получить ссылку можно",
    here: "здесь",
    choose_payment_system_caption: "На нее поступят деньги за ваши скины"
  },
  partner: {
    title: "Оплата счета",
    three_steps_for: "3 шага чтобы",
    deposit_balance: "пополнить свой баланс",
    enter_trade_link: "Для продолжения необходимо ввести трейд ссылку",
    accept_operation: "Подтвердите операцию"
  },
  tradeUrlInput: {
    success_notification: "Трейд ссылка успешна обновлена",
    wrong_link: "Неправильно введена ссылка",
    wrong_link_format: "Неверный формат ссылки",
    save: "СОХРАНИТЬ"
  },
  steamItemsList: {
    search: "Поиск по скинам",
    select_all: "Выбрать все",
    change_sorting: "Изменить сортировку",
    update_inventory: "Обновить инвентарь",
    empty_inventory:
      "Ваш инвентарь пуст или содержит предметы, которые мы не принимаем",
    zero_result: "По вашему запросу ничего не найдено",
    hidden_inventory:
      "Ваш инвентарь скрыт, пожалуйста, измените настройки приватности, перейдя по",
    hidden_inventory_link: "ссылке",
    must_auth: "Для продолжения <br />необходимо авторизоваться",
    load_error_refresh: "обновить",
    load_error: "Не удалось загрузить ваш инвентарь, пожалуйста, попробуйте",
    load_error1:
      "его. Если ошибка повторяется сообщите о ней в поддержку, код ошибки:",
    inventory_total_price:
      "Стоимость вашего инвентаря {steamItemsTotalPrice} руб.",
    disabled_items:
      "В вашем инвентаре есть {disabledSteamItemsCount} предмет(ов), которые мы не принимаем, или они имеют ограничения на обмен",
    max_items: "Можно выбрать не более {maxSteamItemsCount} скинов."
  },
  homeBalanceWithdraw: {
    promocode_error: "Промокод не действителен",
    promocode: "Промокод (необязательно)",
    bonus: "бонус",
    create_deposit_offer_error:
      "Произошла ошибка, пожалуйста повторите попытку позднее. <br> Если ошибка повторяется, обратитесь в поддержку, указав <br> код ошибки",
    account_number: "Номер счета",
    deposit_paymentId: "Номер счета",
    deposit_paymentId_notification:
      "Данный платеж привязан к Вашему аккаунту, после оплаты деньги зачислятся на Ваш баланс.",
    account_number_error: "Номер счета введен не верно",
    email: "Ваш e-mail",
    email_example: "Пример: email@example.com",
    email_wrong: "E-mail введен не верно",
    deposit_account: "Пополнение баланса аккаунта",
    deposit_account_notification:
      "Убедитесь, что используете для пополнения счета Steam аккаунт, который привязан к вашему аккаунту на сайте, иначе средства могут быть не начислены.",
    items_cost: "Стоимость предметов:",
    commission: "Коммиссия платежной системы:",
    total_payout: "Всего к выплате:",
    min_total_payout: "Минимальная сумма к выплате:",
    additional_total_payout: "Дополнительная выплата за уровень:",
    get_money: "Получить деньги",
    confirm_email_notifications:
      "Я согласен получать новости и специальные предложения",
    confirm: "Нажав на кнопку выше, Вы соглашаетесь с",
    privacy_policy: "Политикой конфиденциальности",
    and: "и",
    terms_of_service: "Пользовательским соглашением"
  },
  partnerHeader: {
    title: "Пополнение баланса <br /> сайта",
    caption: "Мгновенно продавай скины <br /> и получай реальные деньги!",
    btn: "к пополнению"
  },
  steamTradeOffer: {
    rate_negative: `Нам очень жаль, что Вы остались недовольны работой нашего сервиса,
            мы делаем все возможное чтобы исправить данную ситуацию. Для
            скорейшего решения проблемы, пожалуйста, обратитесь в нашу службу
            поддержки.`,
    call_support: "Обратиться в поддержку.",
    rate_our_work2: `Пожалуйста, оставьте отзыв о работе нашего сервиса, перейдя
            <a
              style="text-decoration: underline"
              class="text-primary text-weight-bold"
              href="https://ru.trustpilot.com/evaluate/skins.plus"
              target="_blank"
              >по ссылке</a
            >, Вы очень поможете нам в развитии. Если Вы хотите оставить
            негативный отзыв, пожалуйста, сперва обратитесь в поддержку, мы
            обязательно поможем Вам.`,
    rate_our_work: `Пожалуйста, оцените работу нашего сервиса, нажав по соответствующему
            количеству звезд, где 1 звезда – очень плохо, 2 – плохо, 3 –
            удовлетворительно, 4 – хорошо, 5 – отлично`,
    transferred_items: "Переданные предметы",
    wait_accept: "Мы получили Ваш обмен, пожалуйста подождите...",
    processing_trade_offer: "Обработка обмена",
    remain_time: "Осталось времени",
    success_trade:
      "Обмен успешно обработан, средства зачислены по указанным реквизитам",
    error_trade: "Нам не удалось обработать ваше предложение",
    bot_name: "Имя бота",
    trade: "Передайте следующие предметы по",
    trade_link: "трейд-ссылке",
    usage:
      "<span class=\"text-positive \">Внимание! Для предотвращения кражи ваших предметов - удалите <a rel='noreferrer' class='text-weight-bold text-primary cursor-pointer' style='text-decoration: underline' target=\"_blank\" href=\"https://steamcommunity.com/dev/apikey\">API-ключ Steam</a>.</span> Отправьте предложение обмена с выбранными предметами нашему боту, используя трейд-ссылку. Убедитесь что отправляете именно те предметы, что были выбраны, т.к. другие предметы могут быть не оценены и обмен будет отклонен. Обязательно проверяйте получателя ваших предметов (включая ссылку на аккаунт), как при создании обмена, так и при его подтверждении в приложении Steam.",
    give_items: "Передать предметы",
    withdraw_error:
      "Не удалось произвести выплату автоматически, <br> деньги переведены на ваш баланс на сайте,<br> попробуйте провести выплату вручную.",
    escrow_error:
      "К сожалению, у вас не включена двухфакторная <br> аутенфикация Steam, мы вынуждены отклонить ваш обмен.",
    items_to_give_error: "Пожалуйста, не забирайте пердметы у нашего бота",
    tradeOfferStages: {
      waiting: "Ожидание",
      accepting: "Принятие",
      payout: "Выплата",
      completion: "Завершение"
    }
  },
  statsFooter: {
    offers: "сделок",
    completion: "совершено",
    years: "лет",
    experience: "опыта работы со скинами",
    satisfied: "довольных",
    clients: "клиентов",
    minutes: "минут",
    max_time: "макс. время на оплату"
  },
  advantagesFooter: {
    advantage: "Преимущества",
    sell_items: {
      title:
        "Продавай <br><span class='text-weight-light'>игровые скины!</span>",
      advantages: [
        "Минимальная стоимость скина 5 рублей",
        "Высокие цены",
        "Совершенная система приема скинов"
      ]
    },
    get: {
      title:
        "Получай <br><span class='text-weight-light'>реальные деньги!</span>",
      advantages: [
        "Более 10 платежных систем для вывода",
        "Комиссия на вывод отсутствует",
        "Вывод занимает примерно 10 минут"
      ]
    },
    work: {
      title: "Зарабатывай <br><span class='text-weight-light'>с нами!</span>",
      advantages: [
        "Приглашай друзей по рефералке",
        "Получай 1-3% от его продаж",
        "Выводи накопленные бонусы удобным тебе способом"
      ]
    }
  },
  liveOffers: {
    last: "Последние",
    offers: "сделки",
    anonymous: "Анонима",
    by: "от"
  },
  baseFooter: {
    privacy_policy: "Политика конфиденциальности",
    terms_of_service: "Пользовательское соглашение",
    caption:
      "Надежный сервис для продаж скинов Steam с быстрыми выплатами. <br /> Copyright © 2018-2023 . Все права защищены."
  },
  baseHeader: {
    sell: "продать",
    buy: "купить",
    bonuses: "бонусы",
    deposit: "пополнение",
    withdraw: "вывод",
    reviews: "отзывы",
    support: "поддержка"
  },
  chat: {
    need_help: "Нужна помощь?",
    title: "Чат поддержки",
    default_message:
      "Здравствуйте я всего лишь бот, но обязательно Вам помогу, пожалуйста задайте свой вопрос одним предложением.",
    input_placeholder: "Напишите Ваш вопрос..."
  },
  homeHeader: {
    header:
      'Зарабатывай на <br /> скинах <span class="text-weight-light">уже сейчас!</span>',
    caption: "Мгновенно продавай скины <br /> и получай реальные деньги!",
    buy_header:
      'Покупай скины <br /> <span class="text-weight-light">мгновенно!</span>',
    buy_caption:
      "Мгновенно обменивай реальные деньги <br /> и получай крутые скины без задержек!",
    buy_skins: "Приобрести скины"
  },
  buy: {
    title: "Покупка скинов",
    steps: "3 шага чтобы",
    buy_skins: "приобрести скины",
    enter_your_trade: "Введите вашу трейд",
    enter_your_trade_link: "Введите вашу трейд ссылку",
    link: "ссылку",
    choose_skins: "Выберите скины для покупки",
    caption: "По ней будут отправлены скины",
    get_link: "Получить ссылку можно",
    here: "здесь",
    accept: "Подтверждение"
  },
  skinsplusItemsList: {
    search: "Поиск по скинам",
    from: "от",
    to: "до",
    rub: "руб.",
    select_all: "Выбрать все",
    from_zero: "от 0 руб.",
    to_max: "до 999.. руб.",
    sort: "Сортировка",
    close_filters: "Закрыть доп. фильтры",
    open_filters: "Открыть доп. фильтры",
    reset_all: "Сбросить фильтр",
    type: "Тип предмета",
    type_weapon: "Тип оружия",
    rarity: "Раритетность",
    exterior: "Износ",
    zero_result: "По вашему запросу ничего не найдено",
    max_skins: `Можно выбрать не более {maxSelectedCount} скинов.`,
    rarities: [
      "Высшего класса",
      "Превосходный",
      "Армейское качество",
      "Экзотический",
      "Примечательного типа",
      "Контрабанда",
      "Засекреченное",
      "Заслуженный",
      "Ширпотреб",
      "Экстраординарного типа",
      "Исключительный",
      "Мастерский",
      "Запрещенное",
      "Базового класса",
      "Промышленное качество",
      "Тайное"
    ],
    exteriors: [
      "Прямо с завода",
      "Немного поношенное",
      "После полевых испытаний",
      "Поношенно",
      "Закаленное в боях"
    ],
    tags: ["StatTrak™", "Сувенирный", "Стикер"],
    types: [
      "Подарок",
      "Оружие",
      "Пропуск",
      "Ключ",
      "Музыкальный набор",
      "Кейс",
      "Перчатки",
      "Графити",
      "Коллекционный"
    ],
    weaponTypes: [
      "Пистолет-пулемет",
      "Пулемет",
      "Нож",
      "Снайперская винтовка",
      "Винтовка",
      "Дробовик",
      "Пистолет"
    ]
  },
  skinsplusItemsCart: {
    empty_cart: "Ваша корзина пуста",
    cost: "Стоимость:",
    items_count: "Кол-во предметов:",
    count: "шт.",
    buy: "Купить",
    enter_trade_link: "Введите Вашу трейд-ссылку",
    deposit: "Пополнение",
    auth: "Авторизоваться",
    empty_store: "К сожалению, предметы уже раскупили",
    error: "При создании обмена произошла ошибка:"
  }
});
