import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/views/Home.vue";
import Layout from "@/views/Layout.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/be8d77ee1abd6e4906fceb701f644ed3e96a047068b67440be7acd7a12a70de2",
    component: () =>
      import(/* webpackChunkName: "c00000" */ "@/admin-views/AdminLayout.vue"),
    children: [
      {
        path: "/",
        name: "AdminHome",
        component: () =>
          import(/* webpackChunkName: "c00001" */ "@/admin-views/AdminHome.vue")
      },
      {
        path: "steam-bot",
        name: "AdminSteamBot",
        component: () =>
          import(
            /* webpackChunkName: "c00010" */ "@/admin-views/AdminSteamBot.vue"
          )
      },
      {
        path: "steam-bot-proxy",
        name: "AdminSteamBotProxy",
        component: () =>
          import(
            /* webpackChunkName: "c00011" */ "@/admin-views/AdminSteamBotProxy.vue"
          )
      },
      {
        path: "users",
        name: "AdminUsers",
        component: () =>
          import(
            /* webpackChunkName: "c00100" */ "@/admin-views/AdminUsers.vue"
          )
      },
      {
        path: "steam-item",
        name: "AdminSteamItem",
        component: () =>
          import(
            /* webpackChunkName: "c00101" */ "@/admin-views/AdminSteamItem.vue"
          )
      },
      {
        path: "steam-bot-offer",
        name: "AdminSteamBotOffer",
        component: () =>
          import(
            /* webpackChunkName: "c00110" */ "@/admin-views/AdminSteamBotOffer.vue"
          )
      },
      {
        path: "steam-bot-item",
        name: "AdminSteamBotItem",
        component: () =>
          import(
            /* webpackChunkName: "c00111" */ "@/admin-views/AdminSteamBotItem.vue"
          )
      },
      {
        path: "market-csgo-item",
        name: "AdminMarketCsgoItem",
        component: () =>
          import(
            /* webpackChunkName: "c01000" */ "@/admin-views/AdminMarketCsgoItem.vue"
          )
      },
      {
        path: "user-balance-deposit",
        name: "AdminUserBalanceDeposit",
        component: () =>
          import(
            /* webpackChunkName: "c01001" */ "@/admin-views/AdminUserBalanceDeposit.vue"
          )
      },
      {
        path: "user-balance-withdraw",
        name: "AdminUserBalanceWithdraw",
        component: () =>
          import(
            /* webpackChunkName: "c01010" */ "@/admin-views/AdminUserBalanceWithdraw.vue"
          )
      },
      {
        path: "steam-item-price-rule",
        name: "AdminSteamItemPriceRule",
        component: () =>
          import(
            /* webpackChunkName: "c01011" */ "@/admin-views/AdminSteamItemPriceRule.vue"
          )
      },
      {
        path: "firebase-token",
        name: "AdminFirebaseToken",
        component: () =>
          import(
            /* webpackChunkName: "c01100" */ "@/admin-views/AdminFirebaseToken.vue"
          )
      },
      {
        path: "items-counter-rule",
        name: "AdminItemsCounterRule",
        component: () =>
          import(
            /* webpackChunkName: "c01101" */ "@/admin-views/AdminItemsCounterRule.vue"
          )
      },
      {
        path: "items-counter",
        name: "AdminItemsCounter",
        component: () =>
          import(
            /* webpackChunkName: "c01110" */ "@/admin-views/AdminItemsCounter.vue"
          )
      },
      {
        path: "promocodes",
        name: "AdminPromocodes",
        component: () =>
          import(
            /* webpackChunkName: "c01111" */ "@/admin-views/AdminPromocodes.vue"
          )
      },
      {
        path: "settings",
        name: "AdminSettings",
        component: () =>
          import(
            /* webpackChunkName: "c10000" */ "@/admin-views/AdminSettings.vue"
          )
      }
    ]
  },
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/",
        name: "Home",
        component: Home
      },
      {
        path: "/buy",
        name: "Buy",
        component: () => import(/* webpackChunkName: "Buy" */ "@/views/Buy.vue")
      },
      {
        path: "/merchant",
        name: "MerchantLogin",
        component: () => import("@/views/MerchantLogin.vue")
      },
      {
        path: "/profile",
        name: "Profile",
        component: () =>
          import(/* webpackChunkName: "Profile" */ "@/views/Profile.vue")
      },
      {
        path: "/bonus",
        name: "Bonus",
        component: () =>
          import(/* webpackChunkName: "Bonus" */ "@/views/Bonus.vue")
      },
      {
        path: "/faq",
        name: "FAQ",
        component: () => import(/* webpackChunkName: "FAQ" */ "@/views/FAQ.vue")
      },
      {
        path: "/api",
        name: "API",
        component: () => import(/* webpackChunkName: "API" */ "@/views/API.vue")
      },
      {
        path: "/docs",
        name: "DOCS",
        component: () =>
          import(/* webpackChunkName: "DOCS" */ "@/views/API.vue")
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: () =>
          import(
            /* webpackChunkName: "PrivacyPolicy" */ "@/views/PrivacyPolicy.vue"
          )
      },
      {
        path: "/terms-of-service",
        name: "TermsOfService",
        component: () =>
          import(
            /* webpackChunkName: "TermsOfService" */ "@/views/TermsOfService.vue"
          )
      },
      {
        path: "/partner/:userId/:steamId",
        name: "Partner",
        component: () =>
          import(/* webpackChunkName: "Partner" */ "@/views/Partner.vue")
      },
      {
        path: "/partner/:userId",
        name: "PartnerWithPaymentId",
        component: () =>
          import(/* webpackChunkName: "Partner" */ "@/views/Partner.vue")
      },
      {
        path: "/reviews",
        name: "Reviews",
        component: () =>
          import(/* webpackChunkName: "Reviews" */ "@/views/Reviews.vue")
      },
      {
        path: "*",
        redirect: "/"
      }
    ]
  }
];

const router = new VueRouter({
  routes,
  mode: "history"
});

export default router;
