
import {
  Component,
  InjectReactive,
  Prop,
  PropSync,
  Vue,
  Watch
} from "vue-property-decorator";
import SteamItem from "./SteamItem.vue";
import { SteamItemFragment } from "@/graphql/fragments";
import CircleLoading from "@/components/loading/CircleLoading.vue";
import openURL from 'quasar/src/utils/open-url.js';;
import * as _ from "lodash";
import AuthBtn from "@/components/user/AuthBtn.vue";
import { BALANCE_RATES_SYMBOL, USER_PROFILE_SYMBOL } from "@/constants";
import { transliterate } from "transliteration";

@Component({
  components: {
    SteamItem,
    CircleLoading,
    AuthBtn
  }
})
export default class SteamItemsList extends Vue {
  @InjectReactive(BALANCE_RATES_SYMBOL)
  public balanceRates: any;
  openURL = openURL;
  public errorCount = 0;
  public searchText = "";
  public steamItems: any[] = [];
  public maxSteamItemsCount = 25;
  public minSteamItemPrice = 5;
  public sortBy: null | string = null;
  SteamItemFragment = SteamItemFragment;
  @PropSync("appId", { default: 730 }) public _appId!: number;
  @Prop({ default: false }) skip!: boolean;
  @Prop({ type: Boolean, default: false }) showTotalInUsd!: boolean;
  @Prop() steamId: string | undefined;
  @Prop() partnerUserId: string | undefined;
  @Prop() commission: { [key in string | number]: number } | undefined;
  @Prop() showUnTradable: boolean | undefined;
  @Prop() isBotSteamId: boolean | undefined;
  @Prop() tradeUrl: string | undefined;
  @Prop({ default: () => [], type: Array }) selectedSteamItems: any;
  @InjectReactive(USER_PROFILE_SYMBOL) public user;

  @Watch("commission", { immediate: true })
  commissionChanged() {
    if (
      this.commission &&
      Object.keys(this.commission).length &&
      typeof this.commission[this._appId] !== "number"
    ) {
      this._appId = Number(Object.keys(this.commission)[0]);
    }
  }

  get selectedGame() {
    return this.games.find(({ appId }) => appId === this._appId);
  }

  get games() {
    return [
      { appId: 730, icon: "/assets/csgo-icon.svg", label: "CS:GO" },
      { appId: 570, icon: "/assets/dota2-icon.svg", label: "DOTA 2" },
      { appId: 252490, icon: "/assets/rust-icon.svg", label: "RUST" },
      { appId: 440, icon: "/assets/tf2-icon.png", label: "TF 2" }
    ].filter(({ appId }) => !!this.commission && !!this.commission[appId]);
  }

  toggleSort() {
    if (this.sortBy === null) {
      this.sortBy = "PRICE_DESC";
    } else if (this.sortBy === "PRICE_DESC") {
      this.sortBy = "PRICE_ASC";
    } else {
      this.sortBy = null;
    }
  }

  onError() {
    //@ts-ignore
    if (this.errorCount < 1 && this.$refs.refreshBtn) {
      //@ts-ignore
      this.$refs.refreshBtn.click();
    }
    this.errorCount++;
  }
  get steamItemsTotalPrice() {
    return Number(_.sum(this.steamItems.map(({ price }) => price)).toFixed(2));
  }

  onResult({ data }) {
    if (data) {
      this.maxSteamItemsCount = data.getUserSteamItems
        ? data.getUserSteamItems.maxSteamItemsCount
        : this.maxSteamItemsCount;

      this.minSteamItemPrice = data.getUserSteamItems
        ? data.getUserSteamItems.minSteamItemPrice
        : this.minSteamItemPrice;

      this.steamItems = data.getUserSteamItems
        ? data.getUserSteamItems.steamItems.map((_, index) =>
            Object.assign({}, _, { index })
          )
        : [];
    }
  }

  get gamesEnabled() {
    if (!this.commission) {
      return false;
    }

    return Object.keys(this.commission).length > 1;
  }

  get allowedSteamItems() {
    return this.showedSteamItems.filter(
      ({ price }) => price >= this.minSteamItemPrice
    );
  }

  get showedSteamItems() {
    let result = this.steamItems.slice();

    if (this.searchText) {
      const searchText = transliterate(this.searchText);
      result = result.filter(item =>
        new RegExp(`${_.escapeRegExp(searchText)}`, `i`).test(item.name)
      );
    }

    if (this.sortBy) {
      result = result.sort((a, b) => {
        if (this.sortBy === "PRICE_DESC") {
          return b.price - a.price;
        }
        return a.price - b.price;
      });
    }

    result = result.sort((a, b) => {
      if (a.price < this.minSteamItemPrice) {
        return 1;
      }

      if (b.price < this.minSteamItemPrice) {
        return -1;
      }

      return 0;
    });

    return result;
  }

  changeAppId(_appId: number) {
    this._appId = _appId;
    this.$emit("update:selectedSteamItems", []);
  }

  selectAll() {
    let updatedArray: any = [];
    const intersection = this.selectedSteamItems
      .map(item1 =>
        this.allowedSteamItems.find(item2 => item1.index === item2.index)
      )
      .filter(n => !!n);

    if (intersection.length === this.allowedSteamItems.length) {
      updatedArray = this.selectedSteamItems.filter(
        item1 => !intersection.find(item2 => item1.index === item2.index)
      );
    } else {
      updatedArray = [
        ...this.selectedSteamItems.filter(
          item1 =>
            !this.allowedSteamItems.find(item2 => item1.index === item2.index)
        ),
        ...this.allowedSteamItems.map(item => _.cloneDeep(item))
      ];
    }

    if (updatedArray.length <= this.maxSteamItemsCount) {
      this.$emit("update:selectedSteamItems", updatedArray);
    } else {
      this.maxSelectedSteamItemsNotify();
    }
  }

  maxSelectedSteamItemsNotify() {
    this.$q.notify({
      html: true,
      message: this.$t("steamItemsList.max_items", {
        maxSteamItemsCount: this.maxSteamItemsCount
      }) as string,
      position: this.$q.platform.is.mobile ? "bottom" : "bottom-left",
      multiLine: true,
      type: "warning"
    });
  }

  selectSteamItem(steamItem) {
    let updatedArray: any[] = [];
    const selectedIndex = this.selectedSteamItems.findIndex(
      item => item.index === steamItem.index
    );

    if (selectedIndex === -1) {
      updatedArray = [Object.assign({}, _.cloneDeep(steamItem))].concat(
        this.selectedSteamItems
      );
    } else {
      updatedArray = [...this.selectedSteamItems];
      updatedArray.splice(selectedIndex, 1);
    }

    if (updatedArray.length <= this.maxSteamItemsCount) {
      this.$emit("update:selectedSteamItems", updatedArray);
    } else {
      this.maxSelectedSteamItemsNotify();
    }
  }
}
