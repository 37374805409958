export const APP_SCROLL_POS_SYMBOL = "APP_SCROLL_POS_SYMBOL";
export const API_ENDPOINT_SYMBOL = "API_ENDPOINT_SYMBOL";
export const RIGHT_DRAWER_SHOW_SYMBOL = "RIGHT_DRAWER_SHOW_SYMBOL";
export const USER_PROFILE_SYMBOL = "USER_PROFILE_SYMBOL";
export const BALANCE_RATES_SYMBOL = "BALANCE_RATES_SYMBOL";
export const TIME_OFFSET_SYMBOL = "TIME_OFFSET_SYMBOL";
export const BALANCE_CURRENCY_SYMBOL = "BALANCE_CURRENCY_SYMBOL";
export const INNER_HEADER_HEIGHT_SYMBOL = "INNER_HEADER_HEIGHT_SYMBOL";
export const ADMIN_PROFILE_SYMBOL = "ADMIN_PROFILE_SYMBOL";
export const SELL_SERVER_URL_SYMBOL = "SELL_SERVER_URL_SYMBOL";
