var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('q-dialog',{attrs:{"id":"steam-trade-offer","persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('q-card',{staticClass:"relative-position",style:(_vm.$q.platform.is.mobile ? '' : 'min-width: 750px;'),attrs:{"dark":""}},[_c('q-card-section',{staticClass:"row items-center q-pb-none q-pa-none absolute full-width",staticStyle:{"z-index":"1"}},[_c('q-space'),(_vm.tradeOfferStages[_vm.tradeOfferStages.length - 1].state !== 'wait')?_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticClass:"q-mr-xs q-mt-xs",attrs:{"icon":"fas fa-times","flat":"","round":"","dense":""}}):_vm._e()],1),_c('q-card-section',{staticClass:"q-pl-lg relative-position text-uppercase",staticStyle:{"margin-bottom":"8px"}},[_c('span',{staticClass:"text-weight-medium text-h6"},[_vm._v(" "+_vm._s(_vm.$t("steamTradeOffer.processing_trade_offer"))+" ")]),_c('br'),(this.tradeOfferStages[1].state === 'wait')?_c('vac',{attrs:{"end-time":_vm.offerCreatedAt + 15 * 60 * 1000},on:{"finish":function($event){return _vm.cancelTradeOffer()}},scopedSlots:_vm._u([{key:"process",fn:function({ timeObj }){return [_c('span',{staticClass:"text-weight-light text-subtitle2"},[_vm._v(" "+_vm._s(_vm.$t("steamTradeOffer.remain_time"))+" "+_vm._s(`${timeObj.m}:${timeObj.s}`)+" ")]),_c('q-linear-progress',{staticClass:"absolute absolute-bottom full-width",attrs:{"size":"sm","value":timeObj.leftTime / (timeObj.endTime - _vm.offerCreatedAt),"color":"primary"}})]}}],null,false,4029713702)}):(
          this.tradeOfferStages[this.tradeOfferStages.length - 1].state ===
            'wait'
        )?_c('span',{staticClass:"text-primary text-weight-medium"},[_vm._v(_vm._s(_vm.$t("steamTradeOffer.wait_accept")))]):(
          this.tradeOfferStages[this.tradeOfferStages.length - 1].state ===
            'passed'
        )?_c('span',{staticClass:"text-primary text-weight-medium"},[_vm._v(_vm._s(_vm.$t("steamTradeOffer.success_trade")))]):_c('span',{staticClass:"text-negative text-weight-medium"},[_vm._v(_vm._s(_vm.$t("steamTradeOffer.error_trade")))])],1),_c('div',{staticClass:"row trade-offer-state-container q-px-lg q-py-md q-col-gutter-sm"},[_vm._l((_vm.tradeOfferStages),function(tradeOfferStage){return [_c('q-card',{key:tradeOfferStage.value,staticClass:"column bg-transparent flex items-center justify-center col-6 col-md-3",attrs:{"flat":""}},[_c('q-card-section',{staticClass:"q-px-none text-center q-pt-none q-pb-sm"},[_c('q-icon',{class:{
                'fa-spin': tradeOfferStage.state === 'loading'
              },attrs:{"name":_vm.tradeOfferStageStateIcons[tradeOfferStage.state],"color":tradeOfferStage.state === 'error'
                  ? 'negative'
                  : tradeOfferStage.state === 'passed'
                  ? 'positive'
                  : 'primary',"size":"lg"}})],1),_c('q-card-section',{staticClass:"q-pa-none text-weight-bold text-uppercase text-subtitle1 text-center"},[_vm._v(" "+_vm._s(tradeOfferStage.label)+" ")])],1)]})],2),_c('div',{staticClass:"q-px-lg q-py-md"},[_c('div',{staticClass:"text-subtitle1 text-weight-medium text-uppercase text-subtitle-color",class:{ 'text-subtitle2': _vm.$q.platform.is.mobile }},[_c('span',{staticClass:"q-pr-sm"},[_vm._v(_vm._s(_vm.$t("steamTradeOffer.bot_name"))+":")]),_c('a',{staticClass:"text-weight-bold text-primary cursor-pointer",attrs:{"href":`https://steamcommunity.com/profiles/${_vm.offer.steamBotId}/`,"target":"_blank","rel":"noreferrer"}},[_c('ApolloQuery',{attrs:{"query":gql => gql`
                query getSteamBotProfileData($steamBotId: String!) {
                  getSteamBotProfileData(steamBotId: $steamBotId) {
                    avatar
                    name
                  }
                }
              `,"variables":{ steamBotId: _vm.offer.steamBotId },"tag":"span"},scopedSlots:_vm._u([{key:"default",fn:function({ result: { data, error }, query }){return [_c('span',{staticClass:"items-center",staticStyle:{"display":"inline-flex"}},[_c('span',{staticStyle:{"text-decoration":"underline"}},[(data && data.getSteamBotProfileData)?[_vm._v(" "+_vm._s(data.getSteamBotProfileData.name)+" ")]:[_vm._v(" "+_vm._s(_vm.offer.steamBotId)+" ")]],2),(data && data.getSteamBotProfileData)?[_c('q-avatar',{staticClass:"q-ml-sm",attrs:{"size":"24px"}},[_c('q-img',{attrs:{"src":data.getSteamBotProfileData.avatar}})],1)]:_vm._e()],2)]}}])})],1)]),(
          _vm.tradeOfferStages[_vm.tradeOfferStages.length - 1].state !== 'passed'
        )?_c('div',{staticClass:"text-subtitle1 text-weight-medium text-uppercase text-subtitle-color q-mb-md",class:{ 'text-subtitle2': _vm.$q.platform.is.mobile }},[_vm._v(" "+_vm._s(_vm.$t("steamTradeOffer.trade"))+" "),_c('a',{staticClass:"text-weight-bold text-primary cursor-pointer",staticStyle:{"text-decoration":"underline"},attrs:{"href":_vm.offer.bot.tradeUrl,"target":"_blank","rel":"noreferrer"}},[_vm._v(_vm._s(_vm.$t("steamTradeOffer.trade_link")))]),_vm._v(": ")]):_c('div',{staticClass:"text-subtitle1 text-weight-medium text-uppercase text-subtitle-color q-mb-md",class:{ 'text-subtitle2': _vm.$q.platform.is.mobile }},[_vm._v(" "+_vm._s(_vm.$t("steamTradeOffer.transferred_items"))+": ")]),_c('div',{staticClass:"row q-col-gutter-sm"},[_vm._l((_vm.steamItems),function(steamItem,i){return [_c('SteamItem',_vm._b({key:i,staticClass:"col-6 col-md-3"},'SteamItem',steamItem,false))]})],2),(
          _vm.tradeOfferStages[_vm.tradeOfferStages.length - 1].state !== 'passed'
        )?_c('div',{staticClass:"text-left text-subtitle2 text-justify text-subtitle-color q-mt-lg"},[_c('q-icon',{staticClass:"q-pr-xs",staticStyle:{"padding-bottom":"2px"},attrs:{"name":"fas fa-info-circle"}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('steamTradeOffer.usage'))}})],1):(!_vm.isPartnerPage)?_c('div',{staticClass:"text-left text-subtitle1 text-justify q-mt-lg"},[(_vm.rating === 0)?[_vm._v(" "+_vm._s(_vm.$t("steamTradeOffer.rate_our_work"))+" "),_c('q-rating',{staticStyle:{"margin-top":"-2px"},attrs:{"size":"16px","max":5,"color":"primary"},model:{value:(_vm.rating),callback:function ($$v) {_vm.rating=$$v},expression:"rating"}})]:(_vm.rating >= 4)?[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('steamTradeOffer.rate_our_work2'))}}),_c('span',{staticClass:"text-primary text-weight-bold cursor-pointer",on:{"click":() => {
                _vm.$store.commit('toggleChat', true);
                _vm.close();
              }}},[_vm._v(" "+_vm._s(_vm.$t("steamTradeOffer.call_support"))+" ")])]:[_vm._v(" "+_vm._s(_vm.$t("steamTradeOffer.rate_negative"))+" "),_c('span',{staticClass:"text-primary text-weight-bold cursor-pointer",on:{"click":() => {
                _vm.$store.commit('toggleChat', true);
                _vm.close();
              }}},[_vm._v(" "+_vm._s(_vm.$t("steamTradeOffer.call_support"))+" ")])]],2):_vm._e()]),_c('q-card-section',{staticClass:"flex q-pt-none"},[_c('q-space'),(_vm.tradeOfferStages[_vm.tradeOfferStages.length - 1].state === 'wait')?[(_vm.tradeOfferStages[0].state === 'loading')?_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticClass:"text-weight-medium",attrs:{"label":_vm.$t('common.cancel'),"size":_vm.$q.platform.is.mobile ? 'sm' : 'md',"color":"negative","flat":""}}):_vm._e(),_c('q-btn',{staticClass:"text-weight-medium",attrs:{"type":"a","target":"_blank","rel":"noreferrer","size":_vm.$q.platform.is.mobile ? 'sm' : 'md',"href":_vm.offer.bot.tradeUrl,"label":_vm.$t('steamTradeOffer.give_items'),"flat":"","color":"primary"}})]:_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticClass:"text-weight-medium",attrs:{"label":_vm.$t('common.close'),"size":_vm.$q.platform.is.mobile ? 'sm' : 'md',"flat":"","color":"white"}})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }